import React from 'react';
import { RouteComponentProps } from 'react-router';
import qs, { ParsedQuery } from 'query-string'


class WebUrlDetector extends React.Component<RouteComponentProps> {

    componentWillMount() {
        let parsedParameters = qs.parse(this.props.location.search)
        let webUrl = parsedParameters['web-url']
        // console.log('weburldector will mount', parsedParameters, webUrl)
        if (webUrl != null && typeof webUrl == 'string') {
            window.location.replace(webUrl);
        }    
    }
    render() {
        return null
    }
}



export default WebUrlDetector