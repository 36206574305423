let translation = {
    "location": "地點",
    "opening_hour": "開啟時間",
    "phone_number": "電話號碼",
    "coupon": "優惠",
    "description": "介紹",
    "download_our_app_for_more_interesting_spot": "想知更多有趣地方？下載 Scannow啦！",
    "download": "下載",
    "spot_detail": "熱點",
    "remaining_xx_xx": "剩下 {{amount}}/{{max}}",
    "valid_until": "最後兌換日期",
    "available_time": "兌換時間",
    "terms_and_condition": "條款及細則",
    "available_store": "兌換地點",
    "xx_scan_point": "{{scan_point}} 掃點",
    "prize_detail": "禮物",
    "coupon_detail": "優惠",
    "website": "Scannow 網站"
}

export default translation