import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Types from 'MyTypes';
import { connect }  from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux'
import { authActions, authSelectors } from '../../features/auth'
import { drawerActions } from '../../features/drawer'
import { withRouter } from "react-router-dom";
import {
  RouteComponentProps
} from "react-router-dom";
import { commonFeatureSelectors } from '../../features/commonfeature'



export interface AppBarConfig {
  title: string
}

interface Props {
    classes: {
        root: string;
        grow: string;
        menuButton: string;
    }
    
}

const styles = {
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
      color: 'white'
    },
  };

const mapStateToProps = (state: Types.RootState) => ({
    isLoggedIn: authSelectors.getIsLoggedIn(state.auth),
    authentication: authSelectors.getAuthentication(state.auth),
    appBarConfig: commonFeatureSelectors.getAppBarConfig(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) => 
  bindActionCreators(
    {
      login: authActions.login,
      logout: authActions.logout,
      toggleDrawer: drawerActions.toggleDrawer
    },
    dispatch
  )  

type RealProps = RouteComponentProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> 
& Props
  

class ButtonAppBar extends Component<RealProps> {
  render() {
    const { appBarConfig, classes, isLoggedIn, toggleDrawer, history, logout } = this.props;
    // console.log('App bar props: ', this.props)
    
    return (
      <div className={classes.root}>
      
        <AppBar position="fixed">
          <Toolbar>
            <IconButton onClick={()=> toggleDrawer()} className={classes.menuButton} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {appBarConfig.title}
            </Typography>
            {
              // !isLoggedIn ? (<Button 
              //   onClick={()=> history.push('/login')} 
              //   color="inherit">Login</Button>) : 
              //   <Button className={classes.menuButton} onClick={()=>{logout()}}>
              //     Logout
              //   </Button>
            }
            
          </Toolbar>
        </AppBar>
      </div>
    );
  }    
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(withStyles(styles)(ButtonAppBar)));

// export default compose(
//   withRouter,
//   connect(mapStateToProps, mapDispatchToProps),
//   withStyles(styles)
// )(ButtonAppBar)