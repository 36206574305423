import { Button, Card, CardContent, CircularProgress, Container, createStyles, CssBaseline, Grid, Paper, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { CSSProperties } from '@material-ui/styles';
import MyTypes from 'MyTypes';
import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getRemainingAmountOfCoupon } from '../../api/coupon';
import { Prize, RemainingAmountOfCoupon } from '../../api/models';
import { getPrizeById } from '../../api/prize';
import DownloadOurAppBar from '../../components/downloadourappbar';
import { commonFeatureActions, commonFeatureSelectors } from '../../features/commonfeature';
import { handleApiError, isNumber, isServer } from '../../utils';
import { frontloadConnect } from 'react-frontload'
import MetaHandler from '../../components/metahandler'


const mapStateToProps = (state: MyTypes.RootState) => ({
    prizeDetailData: commonFeatureSelectors.getPrizeDetail(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,      
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            cachePrizeDetail: commonFeatureActions.cachePrizeDetail
        },
        dispatch
    )

const styles = ({ palette, spacing }: Theme) => createStyles({
    container: {
        marginBottom: '10%'
    },
    detailImage: {
        display: 'flex',
        justifyContent: 'center',
    },
    columnDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    businessIcon: {
        width: '100%',
        height: 'auto'
    },
    card: {
        height: '100%'
    },
    cardContainer: {
        marginTop: spacing() * 1,
        marginBottom: spacing() * 1
    },
    verticalMargin: {
        marginTop: spacing() * 1,
        marginBottom: spacing() * 1
    },
    icon: {
        marginRight: spacing() * 1
    },
    oneLine: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center'
    },
    textSecondary: {
        color: palette.text.secondary
    },
    bottomBar: {
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: 1300,
        position: 'fixed' as 'fixed',
        backgroundColor: palette.primary.main,
        paddingTop: spacing() * 2,
        paddingBottom: spacing() * 2
    } as CSSProperties
})  ;

type Props = {
    match: match<{prizeId: string;}>,
}

type RealProps = WithNamespaces & WithStyles<typeof styles> & RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type States = {
    // prize?: Prize,
    // remainingAmount?: number;
}

const getPrizeIdFromProps = (props: RealProps) => {
    return props.match.params.prizeId
}

const frontload = async(props: RealProps) => {
    let prizeId = getPrizeIdFromProps(props)
    const { t, showMessage } = props
    let { language } = props.i18n;
    try {
        let resp = await getPrizeById(prizeId, language);
        props.cachePrizeDetail({prize: resp.data.message as Prize})
        // console.log('Frontload something', resp.data.message);
    } catch (error) {
        console.log('Error while loading spot', error)
        console.log(error);
        props.cachePrizeDetail(null)
        if (!isServer) {
            if (!isNumber(prizeId)) {
                showMessage({ message: t('invalidId', {id: prizeId})})
            } else {
                showMessage({message: t('unknown_error')})
            }
        }
    }
}


class PrizeDetailPage extends React.Component<RealProps, States> {
    
    componentDidMount() {
        let { t } = this.props;
        this.props.updateAppBarConfig({ title: t('prize_detail') as string })
        // if (this.idIsOk()) {
        //     this.fetchAll()
        // } else {
        //     alert("Invalid Prize Id")
        //     window.location.replace('https://scannow.gg')
        // }
    }

    getPrizeId = () => {
        return this.props.match.params.prizeId
    }

    idIsOk = () => {
        return isNumber(this.props.match.params.prizeId)
    }

    fetchAll = () => {
        frontload(this.props).then(()=>{})
    }

    // fetchPrize = () => {
    //     getPrizeById(this.getPrizeId(), this.getI18nLanguage()).then((resp) => {
    //         const prize = resp.data.message as Prize
    //         if (prize != null) {
    //             this.setState({
    //                 prize
    //             }, ()=> {
    //                 let prize_ = this.state.prize;
    //                 if (prize_ != null) {
    //                     let prizeName = prize_.name
    //                     if (prizeName != null) {
    //                         this.props.updateAppBarConfig({
    //                             title: prizeName
    //                         })
    //                     }
    //                 }
    //             })
    //         }
    //     }).catch((error) => {
    //         handleApiError(error, this.props.showMessage)
    //     })
    // }

    getI18nLanguage = () => {
        let { i18n } = this.props;
        return i18n.language
    }

    renderPrize(prize: Prize) {
        const { icon, detailImage, card, oneLine, cardContainer } = this.props.classes
        const { t } = this.props

        let scanPoint = prize.price
       

        
        return (
            <main>
                <Grid container justify="center">
                    <Grid item xs={12} md={8}>
                        <Paper className={detailImage}>
                            <img style={{
                                width: '100%',
                                height: '100%'
                            }}src={prize.detailImage != null ? prize.detailImage.url : undefined}/>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid className={cardContainer} container justify="center" spacing={2} alignItems="stretch">
                    <Grid item xs={12} md={8}>
                        {/* Name and Scan-Point card */}
                        <Card className={card}>
                            <CardContent>
                                <Typography component="h5" variant="h5">{prize.name}</Typography>
                                <Typography variant="subtitle1" color="textSecondary">{t('xx_scan_point', {scan_point: scanPoint})} </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid className={cardContainer} container justify="center" spacing={2} alignItems="stretch">
                    <Grid item xs={12} md={8}>
                        {/* Description */}
                        <Card className={card}>
                            <CardContent>
                                <div className={oneLine}>
                                    <DescriptionIcon className={icon} color='primary'/>
                                    <Typography variant="subtitle1">{t('description')}</Typography>
                                </div>
                                <Typography color="textSecondary">
                                    {prize.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                
            </main>
        )
    }


    render() {
        const { container, bottomBar } = this.props.classes;
        let state = this.state == null ? {} : this.state
        let { t, prizeDetailData } = this.props;
        // let prizeDetailData = this.props.prizeDetailData;
        if (prizeDetailData == null) {
            prizeDetailData = {}
        }
        const { prize } = prizeDetailData;
        return (
            <React.Fragment>
                    <CssBaseline />
                    {
                        prize != null ? 
                        <MetaHandler 
                            title={prize.name} 
                            description={prize.description}
                            image={prize.listingImage != null ? { 
                                url: prize.listingImage.url,
                                alt: prize.listingImage.name
                            } : undefined}
                            />
                        : <MetaHandler/>
                    }
                    <Container className={container} maxWidth="lg">
                        {
                            prize != null ? this.renderPrize(prize) : 
                            
                            <Grid container alignItems='center' direction='column'>
                                <Grid item>
                                    <CircularProgress/>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.fetchAll}>
                                        {t('retry')}
                                    </Button>
                                </Grid>
                            </Grid>
        
                        }
                        
                    
                    </Container>
                    <DownloadOurAppBar/>
            </React.Fragment>
            
        )
    }
    
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces()(
    frontloadConnect(frontload, {
        onMount: true,
        onUpdate: false
    })
        (PrizeDetailPage)
    
    ))))