import { CommonState } from './reducer'

export const getUserCouponOwnershipsData = (state: CommonState) => state.userCouponOwnershipsData

export const getUserShippingOrders = (state: CommonState) => state.userShippingOrdersData

export const getSpotsData = (state: CommonState) => state.spotsData

export const getMessages = (state: CommonState) => state.messages

export const getUsersData = (state: CommonState) => state.usersData

export const getGamesData = (state: CommonState) => state.gamesData

export const getBusinessData = (state: CommonState) => state.businessData

export const getUserData = (state: CommonState) => state.userData

export const getAppBarConfig = (state: CommonState) => state.appBarconfig

export const getEditedShippingOrders = (state: CommonState) => state.editedShippingOrders

export const getGeographicLocationData = (state: CommonState) => state.geographicLocationsData

export const getPhoneNumberData = (state: CommonState) => state.phoneNumberData

export const getGameBannerData = (state: CommonState) => state.gameBannerData

export const getCouponBannerData = (state: CommonState) => state.couponBannerData

export const getMajorBannerData = (state: CommonState) => state.majorBannerData

export const getSpotBannerData = (state: CommonState) => state.spotBannerData

export const getRewardBundleData = (state: CommonState) => state.rewardBundleData

export const getCouponData = (state: CommonState) => state.couponData

export const getQRCodeEntityData = (state: CommonState) => state.qrCodeEntityData

export const getSpotDetail = (state: CommonState) => state.spotDetailData

export const getCouponDetail = (state: CommonState) => state.couponDetailData

export const getPrizeDetail = (state: CommonState) => state.prizeDetailData