import { Button, Card, CardActionArea, CardContent, CircularProgress, Container, createStyles, CssBaseline, Grid, Paper, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NearMeIcon from '@material-ui/icons/NearMe';
import PhoneIcon from '@material-ui/icons/Phone';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MyTypes from 'MyTypes';
import React from 'react';
import { frontloadConnect } from 'react-frontload';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { Spot } from '../../api/models';
import { getSpotById } from '../../api/spot';
import DownloadOurAppBar from '../../components/downloadourappbar';
import MetaHandler from '../../components/metahandler';
import { commonFeatureActions } from '../../features/commonfeature';
import { getSpotDetail } from '../../features/commonfeature/selectors';
import { isNumber, isServer } from '../../utils';


const mapStateToProps = (state: MyTypes.RootState) => ({
    spot: getSpotDetail(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,      
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig,
            cacheSpotDetail: commonFeatureActions.cacheSpotDetail,
            
        },
        dispatch
    )

const styles = ({ palette, spacing }: Theme) => createStyles({
    container: {
        marginBottom: '10%'
    },
    detailImage: {
        display: 'flex',
        justifyContent: 'center',
    },
    columnDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    businessIcon: {
        width: '100%',
        height: 'auto'
    },
    card: {
        height: '100%'
    },
    cardContainer: {
        marginTop: spacing() * 1,
        marginBottom: spacing() * 1
    },
    oneLine: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center'
    },
    textSecondary: {
        color: palette.text.secondary
    },
    icon: {
        marginRight: spacing() * 1
    },
})  ;

type Props = {
    match: match<{spotId: string;}>,
    // classes: {
    //     spotImage: string;
    // }
}

type RealProps = WithNamespaces & WithStyles<typeof styles> & RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type States = {
    spot?: Spot,
}

const getSpotIdFromProps = (props: RealProps) => {
    return props.match.params.spotId;
}

const frontload = async(props: RealProps) => {
    let spotId = getSpotIdFromProps(props)
    const { t, showMessage } = props
    let { language } = props.i18n;
    try {
        let resp = await getSpotById(spotId, language);
        props.cacheSpotDetail(resp.data.message as Spot)
        // console.log('Frontload something', resp.data.message);
    } catch (error) {
        console.log('Error while loading spot', error)
        console.log(error);
        props.cacheSpotDetail(null)
        if (!isServer) {
            if (!isNumber(spotId)) {
                showMessage({ message: t('invalid_spot_id', {spot_id: spotId})})
            } else {
                showMessage({message: t('unknown_error')})
            }
        }
    }
}

class SpotDetailPage extends React.Component<RealProps, States> {
    
    componentDidMount() {
        let { t } = this.props;
        this.props.updateAppBarConfig({ title: t('spot_detail') as string })
    }

    
    shouldComponentUpdate(nextProps: RealProps): boolean {
        if (getSpotIdFromProps(nextProps) != getSpotIdFromProps(this.props)) {
            frontload(nextProps)
        }
        return true
    }

    getSpotId = () => {
        return this.props.match.params.spotId
    }

    idIsOk = () => {
        return isNumber(this.props.match.params.spotId)
    }

    fetchAll = () => {
        frontload(this.props).then(()=> {})
    }

    getI18nLanguage = () => {
        let { i18n } = this.props;
        return i18n.language
    }

    renderSpot(spot: Spot) {
        const { icon, detailImage, businessIcon, card, oneLine, cardContainer, textSecondary } = this.props.classes
        const { t, history } = this.props
        
        // const { spotImage } = useStyles()
        console.log('spotImage: ', detailImage)
        let businessIconUrl = undefined
        let businessName = undefined
        let locationStr = undefined
        let phoneNumberStr = undefined
        let couponDescription = undefined
        

        if (spot.qrCodeEntities != null && spot.qrCodeEntities.length > 0) {
            const qr = spot.qrCodeEntities[0]
            const coupon = qr.rewardBundle.coupons[0]
            couponDescription = coupon.description
        }
        
        if (spot.phoneNumber != null) {
            phoneNumberStr = `${spot.phoneNumber.subscriberNumber}`
        }
        
        if (spot.location != null) {
            locationStr = spot.location.description
        }
        if (spot.businesses != null && spot.businesses.length >0) {
            let business0 = spot.businesses[0]
            businessName = business0.name
            businessIconUrl = business0.businessIcon != null ? business0.businessIcon.url : undefined
        }
        return (
            <main>
                <Grid container justify="center">
                    <Grid item xs={12} md={8}>
                        <Paper className={detailImage}>
                            <img style={{
                                width: '100%',
                                height: '100%'
                            }}src={spot.images != null && spot.images.length > 0 ? spot.images[0].url : undefined}/>
                        </Paper>
                    </Grid>
                </Grid>
                
                <Grid className={cardContainer} container justify="center" spacing={2} alignItems="stretch">
                    <Grid item xs={12} md={8}>
                        {/* Name card */}
                        <Card className={card}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={9} sm={10}>
                                        <Typography component="h5" variant="h5">{spot.name}</Typography>
                                        <div>
                                            <Typography display="inline"variant="subtitle1" color="textSecondary">{businessName} </Typography>
                                            <Typography display="inline" variant="subtitle1" color="textSecondary">{spot.category.name}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} sm={2}>
                                        <img className={businessIcon} 

                                            src={businessIconUrl}></img>
                                        
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid> 
                    <Grid item xs={12} md={4}>
                        { /** location card  */}
                        <Card className={card}>
                            <CardContent>
                                    <div className={oneLine}>
                                        <NearMeIcon className={icon} color='primary'/>
                                        <Typography variant="subtitle1">{t('location')}</Typography>
                                    </div>
                                    
                                    <Typography color="textSecondary">
                                        {locationStr}
                                    </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                
                <Grid className={cardContainer} container justify="center" spacing={2} alignItems="stretch">
                    <Grid item xs={12} md={6}>
                        { /** Phone Number and Opening Hour Card card  */}
                        <Card className={card}>
                            <CardContent>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <div className={oneLine}>
                                                <PhoneIcon className={icon} color='primary'/> 
                                                <Typography display='inline' variant="subtitle1">{t('phone_number')}</Typography>
                                            </div>
                                            
                                            <Typography color="textSecondary">
                                                <Button>
                                                    <a className={textSecondary} href={`tel:${phoneNumberStr}`}>{phoneNumberStr}</a>
                                                </Button>
                                            </Typography>
                                            {/* <Button onClick={()=> {
                                                history.push('/spot/3')
                                            }}>To Spot 3</Button> */}
                                        </Grid>
                                        <Grid item xs ={7}>
                                            <div className={oneLine}>
                                                <AccessTimeIcon className={icon} color='primary'/> 
                                                <Typography display='inline' variant="subtitle1">{t('opening_hour')}</Typography>
                                            </div>
                                            <Typography style={{verticalAlign: 'top'}} color="textSecondary">
                                                {spot.availablePeriodDescription}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        { /** Coupon Description card  */}
                        <Card className={card}>
                            <CardContent>
                                <CardActionArea>
                                    <div className={oneLine}>
                                        <ReceiptIcon className={icon} color='primary'/>
                                        <Typography variant="subtitle1">{t('coupon')}</Typography>
                                    </div>
                                    <Typography color="textSecondary">
                                        {couponDescription}
                                    </Typography>
                                </CardActionArea>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                
                
                { /** Spot  Description card  */}
                <Card className={card}>
                    <CardContent>
                        <CardActionArea>
                            <Grid container>
                                <Grid item>
                                    <Typography variant="subtitle1">{t('description')}</Typography>
                                </Grid>
                            </Grid>
                            <Typography color="textSecondary">
                                {spot.description}
                            </Typography>
                        </CardActionArea>
                    </CardContent>
                </Card>
            </main>
        )
    }


    render() {
        const { container } = this.props.classes;
        let state = this.state == null ? {} : this.state
        const { t } = this.props;
        const { spot, history } = this.props
        return (
            <React.Fragment>
                    <CssBaseline />

                    {
                        spot != null ? 
                        <MetaHandler 
                            title={spot.name} 
                            description={spot.description}
                            image={spot.squareImage != null ? { 
                                url: spot.squareImage.url,
                                alt: spot.squareImage.name
                            } : undefined}
                            />
                        : <MetaHandler/>
                    }
                    
                    <Container className={container} maxWidth="lg">

                        {
                            spot != null ? this.renderSpot(spot) : 
                            
                            <Grid container alignItems='center' direction='column'>
                                <Grid item>
                                    <CircularProgress/>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.fetchAll}>
                                        {t('retry')}
                                    </Button>
                                </Grid>
                            </Grid>
                            
                        }
                        
                    </Container>

                    <DownloadOurAppBar/>
            </React.Fragment>
            
        )
    }
    
}

export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces()
(
    frontloadConnect(frontload, {
        onMount: true,
        onUpdate: false
    })
     (SpotDetailPage)
))))