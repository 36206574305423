import { Button, Card, CardContent, CircularProgress, Container, createStyles, CssBaseline, Grid, Paper, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DescriptionIcon from '@material-ui/icons/Description';
import StoreIcon from '@material-ui/icons/Store';
import { CSSProperties } from '@material-ui/styles';
import MyTypes from 'MyTypes';
import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getCouponById, getRemainingAmountOfCoupon } from '../../api/coupon';
import { BusinessBranch, Coupon, RemainingAmountOfCoupon } from '../../api/models';
import DownloadOurAppBar from '../../components/downloadourappbar';
import { commonFeatureActions, commonFeatureSelectors } from '../../features/commonfeature';
import { handleApiError, isNumber, isServer } from '../../utils';
import { frontloadConnect } from 'react-frontload';
import MetaHandler from '../../components/metahandler'


const mapStateToProps = (state: MyTypes.RootState) => ({
    couponDetailData: commonFeatureSelectors.getCouponDetail(state.commonFeatures) 
})

const mapDispatchToProps = (dispatch: Dispatch<MyTypes.RootAction>) => 
    bindActionCreators(
        {
            showMessage: commonFeatureActions.showMessage,      
            updateAppBarConfig: commonFeatureActions.updateAppBarConfig  ,
            cacheCouponDetail: commonFeatureActions.cacheCouponDetail
        },
        dispatch
    )

const styles = ({ palette, spacing }: Theme) => createStyles({
    container: {
        marginBottom: '10%'
    },
    detailImage: {
        display: 'flex',
        justifyContent: 'center',
    },
    columnDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    businessIcon: {
        width: '100%',
        height: 'auto'
    },
    card: {
        height: '100%'
    },
    cardContainer: {
        marginTop: spacing() * 1,
        marginBottom: spacing() * 1
    },
    verticalMargin: {
        marginTop: spacing() * 1,
        marginBottom: spacing() * 1
    },
    icon: {
        marginRight: spacing() * 1
    },
    oneLine: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center'
    },
    textSecondary: {
        color: palette.text.secondary
    },
    bottomBar: {
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: 1300,
        position: 'fixed' as 'fixed',
        backgroundColor: palette.primary.main,
        paddingTop: spacing() * 2,
        paddingBottom: spacing() * 2
    } as CSSProperties
})  ;

type Props = {
    match: match<{couponId: string;}>,
}

type RealProps = WithNamespaces & WithStyles<typeof styles> & RouteComponentProps & Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type States = {
}

const getCouponIdFromProps = (props: RealProps) => {
    return props.match.params.couponId
}

const frontload = async(props: RealProps) => {
    let couponId = getCouponIdFromProps(props)
    const { t, showMessage } = props
    let { language } = props.i18n;
    try {
        let [ couponResp, remainingAmountResp ] = await Promise.all([
            getCouponById(couponId, language),
            getRemainingAmountOfCoupon(couponId)
        ])
        console.log('remainingAmountResp', remainingAmountResp.data.message);
        props.cacheCouponDetail({
            coupon: couponResp.data.message as Coupon,
            remainingAmount: (remainingAmountResp.data.message as RemainingAmountOfCoupon).remainingAmount
        });
        // console.log('Frontload something', resp.data.message);
    } catch (error) {
        console.log('Error while loading spot', error)
        console.log(error);
        props.cacheCouponDetail(null)
        if (!isServer) {
            if (!isNumber(couponId)) {
                showMessage({ message: t('invalid_id', {id: couponId})})
            } else {
                showMessage({message: t('unknown_error')})
            }
        }
    }
}


class CouponDetailPage extends React.Component<RealProps, States> {
    
    componentDidMount() {
        let { t } = this.props;
        this.props.updateAppBarConfig({ title: t('coupon_detail') as string })
    }

    getCouponId = () => {
        return this.props.match.params.couponId
    }

    idIsOk = () => {
        return isNumber(this.props.match.params.couponId)
    }

    fetchAll = () => {
        frontload(this.props).then(()=>{});
    }

    fetchRemainingAmountOfCoupon = ()=> {
        getRemainingAmountOfCoupon(this.getCouponId()).then((resp) => {
            const remainingAmount = (resp.data.message as RemainingAmountOfCoupon).remainingAmount
            this.setState({
                remainingAmount
            })
        })
    }

    getI18nLanguage = () => {
        let { i18n } = this.props;
        return i18n.language
    }

    renderCoupon(coupon: Coupon, remainingAmount?: number) {
        const { icon, verticalMargin, detailImage, businessIcon, card, oneLine, cardContainer, textSecondary } = this.props.classes
        const { t } = this.props
        // const { remainingAmount } = this.state
        let businessName = undefined
        let businessBranch0 = undefined
        let business0 = undefined

        console.log('new Date(coupon.validUntil)', new Date(coupon.validUntil))
        if(coupon.businessBranches != null && coupon.businessBranches.length > 0) {
            businessBranch0 = coupon.businessBranches[0]
            if (businessBranch0.business != null) {
                business0 = businessBranch0.business
                businessName = business0.name
            }
        }

        
        return (
            <main>
                <Grid container justify="center">
                    <Grid item xs={12} md={8}>
                        <Paper className={detailImage}>
                            <img style={{
                                width: '100%',
                                height: '100%'
                            }}src={coupon.images != null && coupon.images.length > 0 ? coupon.images[0].url : undefined}/>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid className={cardContainer} container justify="center" spacing={2} alignItems="stretch">
                    <Grid item xs={12} md={6}>
                        {/* Name, business, remaining amount, expired or not card */}
                        <Card className={card}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography component="h5" variant="h5">{coupon.name}</Typography>

                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                                <Typography display="inline" variant="subtitle1" color="textSecondary">{businessName} </Typography>
                                                <Typography display="inline" variant="subtitle1" color="textSecondary">
                                                    {coupon.expired ? t('expired') : null}
                                                </Typography>
                                            </div>
                                            <Typography variant="subtitle1" color="textSecondary">{t('remaining_xx_xx', {amount: remainingAmount, max: coupon.maximumAmount})} </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* Valid Until and Available time description Card */}
                        <Card className={card}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <div className={oneLine}>
                                            <AccessTimeIcon className={icon} color='primary'/> 
                                            <Typography style={{marginLeft: '0.5%'}} display='inline' variant="subtitle1">{t('valid_until')}</Typography>
                                        </div>
                                        <Typography style={{verticalAlign: 'top'}} color="textSecondary">
                                            {new Date(coupon.validUntil).toDateString()}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <div className={oneLine}>
                                            <AccessTimeIcon className={icon} color='primary'/> 
                                            <Typography style={{marginLeft: '0.5%'}} display='inline' variant="subtitle1">{t('available_time')}</Typography>
                                        </div>
                                        <Typography style={{verticalAlign: 'top'}} color="textSecondary">
                                            {coupon.allowedPeriodDescription}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid className={cardContainer} container justify="center" spacing={2} alignItems="stretch">
                    <Grid item xs={12} md={6}>
                        { /** Description */}
                        <Card className={card}>
                            <CardContent>
                                <div className={oneLine}>
                                    <DescriptionIcon className={icon} color='primary'/>
                                    <Typography variant="subtitle1">{t('description')}</Typography>
                                </div>
                                <Typography color="textSecondary">
                                    {coupon.description}
                                </Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        { /** Terms and Condition */}
                        <Card className={card}>
                            <CardContent>
                                <div className={oneLine}>
                                    <DescriptionIcon className={icon} color='primary'/>
                                    <Typography variant="subtitle1">{t('terms_and_condition')}</Typography>
                                </div>
                                <Typography color="textSecondary">
                                    {coupon.termAndConditions}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid className={cardContainer} container justify="center" spacing={2} alignItems="stretch">
                    <Grid item xs={12}>
                        <Card className={card}>
                            <CardContent>
                                <div className={oneLine}>
                                    <StoreIcon className={icon} color='primary'/>
                                    <Typography variant="subtitle1">{t('available_store')}</Typography>
                                </div>
                                {
                                    coupon.businessBranches  == null ? null : coupon.businessBranches.map((branch : BusinessBranch) => {
                                        return (
                                            <div className={verticalMargin}>
                                                <Typography variant='h6'>{branch.name}</Typography>
                                                <Typography color="textSecondary">{branch.location != null ? branch.location.description : null}</Typography>
                                            </div>
                                        )
                                    })
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                
            </main>
        )
    }


    render() {
        const { container, bottomBar } = this.props.classes;
        let state = this.state == null ? {} : this.state
        let { t, couponDetailData } = this.props;
        if (couponDetailData == null) {
            couponDetailData = {}
        }

        const { coupon, remainingAmount } = couponDetailData;
        return (
            <React.Fragment>
                    <CssBaseline />
                    {
                        coupon != null ?
                        <MetaHandler 
                            title={coupon.name} 
                            description={coupon.description}
                            image={coupon.squareImage != null ? { 
                                url: coupon.squareImage.url,
                                alt: coupon.squareImage.name
                            } : undefined}
                            />
                        : <MetaHandler/>
                    }
                    <Container className={container} maxWidth="lg">

                        {
                            coupon != null ? this.renderCoupon(coupon, remainingAmount) : 
                            
                            <Grid container alignItems='center' direction='column'>
                                <Grid item>
                                    <CircularProgress/>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.fetchAll}>
                                        {t('retry')}
                                    </Button>
                                </Grid>
                            </Grid>
        
                        }
                        
                        
                    </Container>
                    <DownloadOurAppBar/>
            </React.Fragment>
            
        )
    }
    
}


export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces()(
    frontloadConnect(frontload, {
        onUpdate: false,
        onMount: true
    })
    (CouponDetailPage)
))))