import { ActionType, getType } from 'typesafe-actions'
import * as commonActions from './actions'
import { combineReducers } from 'redux';
import { Message } from './models'
import { UserEntity, Pagination, 
    Game, 
    Spot,
    Business,
    ApiPaginatedData,
    ApiData,
    ShippingOrder,
    CouponOwnership,
    Location,
    PhoneNumber,
    GameBanner,
    CouponBanner,
    MajorBanner,
    SpotBanner,
    RewardBundle,
    Coupon,
    QRCodeEntity,
    Prize
} from '../../api';
import { AppBarConfig } from '../../commonui/myappbar/myappbar';

export type CommonState = {
    readonly messages: Message[],
    readonly usersData: {
        cached?: Pagination<UserEntity>,
        loading: boolean
    },
    readonly gamesData: {
        cached?: Pagination<Game>,
        loading: boolean
    },
    readonly spotsData: {
        cached?: Pagination<Spot>,
        loading: boolean
    },
    readonly businessData: ApiPaginatedData<Business>
    readonly userData: ApiData<UserEntity>,
    readonly userShippingOrdersData: ApiPaginatedData<ShippingOrder>,
    readonly userCouponOwnershipsData: ApiPaginatedData<CouponOwnership>,
    readonly appBarconfig: AppBarConfig,
    readonly editedShippingOrders: ShippingOrder[],
    readonly geographicLocationsData: ApiPaginatedData<Location>,
    readonly phoneNumberData: ApiPaginatedData<PhoneNumber>,
    readonly gameBannerData: ApiPaginatedData<GameBanner>,
    readonly couponBannerData: ApiPaginatedData<CouponBanner>,
    readonly majorBannerData: ApiPaginatedData<MajorBanner>,
    readonly spotBannerData: ApiPaginatedData<SpotBanner>,
    readonly rewardBundleData: ApiPaginatedData<RewardBundle>,
    readonly couponData: ApiPaginatedData<Coupon>,
    readonly qrCodeEntityData: ApiPaginatedData<QRCodeEntity>,
    readonly spotDetailData: Spot|null,
    readonly couponDetailData: {
        coupon?: Coupon,
        remainingAmount?: number
    } | null,
    readonly prizeDetailData: {
        prize?: Prize
    } | null
}

export type CommonAction = ActionType<typeof commonActions>

export default combineReducers<CommonState, CommonAction> ({
    prizeDetailData: (state = null, action) => {
        switch(action.type) {
            case getType(commonActions.cachePrizeDetail):
                if (state == null) {
                    state = null
                }
                return {
                    ...state,
                    ...action.payload
                };
            default: return state
        }
    },
    couponDetailData: (state = null, action) => {
        switch(action.type) {
            case getType(commonActions.cacheCouponDetail):
                if (state == null) {
                    state = {}
                }
                return {
                    ...state,
                    ...action.payload
                }
            default: return state;
        }
    },
    spotDetailData: (state = null, action) => {
        switch(action.type) {
            case getType(commonActions.cacheSpotDetail):
                return action.payload
            default:
                return state
        }
    },
    qrCodeEntityData: ( state = { loading: false }, action ) => {
        switch(action.type) {
            case getType(commonActions.cachePaginatedQRCodeEntity):
                return {
                    loading: false,
                    cached: action.payload
                }
            case getType(commonActions.getPaginatedQRCodeEntityRequest):
                return {
                    loading: true,
                    ...state
                }
            case getType(commonActions.errorGetPatinatedQRCodeEntity):
                return {
                    loading: false,
                    ...state
                }
            default: return state
        }
    },
    couponData: ( state = { loading: false }, action ) => {
        switch(action.type) {
            case getType(commonActions.cachePaginatedCoupon):
                return {
                    loading: false,
                    cached: action.payload
                }
            case getType(commonActions.getPaginatedCouponRequest):
                return {
                    loading: true,
                    ...state
                }
            case getType(commonActions.errorGetPatinatedCoupon):
                return {
                    loading: false,
                    ...state
                }
            default: return state
        }
    },
    rewardBundleData: ( state = { loading: false }, action ) => {
        switch(action.type) {
            case getType(commonActions.cachePaginatedRewardBundle):
                return {
                    loading: false,
                    cached: action.payload
                }
            case getType(commonActions.getPaginatedRewardBundleRequest):
                return {
                    loading: true,
                    ...state
                }
            case getType(commonActions.errorGetPatinatedRewardBundle):
                return {
                    loading: false,
                    ...state
                }
            default: return state
        }
    },
    spotBannerData: ( state = { loading: false }, action ) => {
        switch(action.type) {
            case getType(commonActions.cachePaginatedSpotBanner):
                return {
                    loading: false,
                    cached: action.payload
                }
            case getType(commonActions.getPaginatedSpotBannerRequest):
                return {
                    loading: true,
                    ...state
                }
            case getType(commonActions.errorGetPatinatedSpotBanner):
                return {
                    loading: false,
                    ...state
                }
            default: return state
        }
    },
    majorBannerData: ( state = { loading: false }, action ) => {
        switch(action.type) {
            case getType(commonActions.cachePaginatedMajorBanner):
                return {
                    loading: false,
                    cached: action.payload
                }
            case getType(commonActions.getPaginatedMajorBannerRequest):
                return {
                    loading: true,
                    ...state
                }
            case getType(commonActions.errorGetPatinatedMajorBanner):
                return {
                    loading: false,
                    ...state
                }
            default: return state
        }
    },
    couponBannerData: ( state = { loading: false }, action ) => {
        switch(action.type) {
            case getType(commonActions.cachePaginatedCouponBanner):
                return {
                    loading: false,
                    cached: action.payload
                }
            case getType(commonActions.getPaginatedCouponBannerRequest):
                return {
                    loading: true,
                    ...state
                }
            case getType(commonActions.errorGetPatinatedCouponBanner):
                return {
                    loading: false,
                    ...state
                }
            default: return state
        }
    },
    gameBannerData: ( state = { loading: false }, action ) => {
        switch(action.type) {
            case getType(commonActions.cachePaginatedGameBanner):
                return {
                    loading: false,
                    cached: action.payload
                }
            case getType(commonActions.getPaginatedGameBannerRequest):
                return {
                    loading: true,
                    ...state
                }
            case getType(commonActions.errorGetPatinatedGameBanner):
                return {
                    loading: false,
                    ...state
                }
            default: return state
        }
    },
    phoneNumberData: ( state = { loading: false }, action ) => {
        switch(action.type) {
            case getType(commonActions.cachePaginatedPhoneNumber):
                return {
                    loading: false,
                    cached: action.payload
                }
            case getType(commonActions.getPaginatedPhoneNumberRequest):
                return {
                    loading: true,
                    ...state
                }
            case getType(commonActions.errorGetPatinatedPhoneNumber):
                return {
                    loading: false,
                    ...state
                }
            default: return state
        }
    },
    geographicLocationsData: ( state = { loading: false }, action ) => {
        switch(action.type) {
            case getType(commonActions.cachePaginatedGeographicLocation):
                return {
                    loading: false,
                    cached: action.payload
                }
            case getType(commonActions.getPaginatedGeographicLocationRequest):
                return {
                    loading: true,
                    ...state
                }
            case getType(commonActions.errorGetPatinatedGeographicLocation):
                return {
                    loading: false,
                    ...state
                }
            default: return state
        }
    },
    editedShippingOrders: ( state = [], action) => {
        switch(action.type) {
            case getType(commonActions.onShippingOrderEdited): 
                return action.payload
            default: return state
        }
    },
    appBarconfig: (state = {title: ''}, action) => {
        switch(action.type) {
            case getType(commonActions.updateAppBarConfig):
                return {
                    ...state,
                    ...action.payload
                }
            default:
                return state
        }
    },
    userCouponOwnershipsData: (state = { loading: false}, action) => {
        switch(action.type) {
            case getType(commonActions.getPaginatedUserCouponOwnershipRequest):
                return {
                    ...state,
                    loading: true
                }
            case getType(commonActions.errorGetPaginatedUserCouponOwnership):
                return {
                    ...state,
                    loading: false
                }
            case getType(commonActions.cachePaginatedUserCouponOwnership):
                return {
                    loading: false,
                    cached: action.payload
                }
            default:
                return state

        }
    },
    userShippingOrdersData: (state = {loading: false}, action ) => {
        switch(action.type) {
            case getType(commonActions.getPaginatedUserShippingOrdersRequest):
                return {
                    ...state,
                    loading: true
                }
            case getType(commonActions.errorGetPaginatedUserShippingOrders):
                return {
                    ...state,
                    loading: false
                }
            case getType(commonActions.cachePaginatedUserShippingOrders):
                return {
                    cached: action.payload,
                    loading: false
                }
            default:
                return state
        }
    },
    userData: (state = {loading: false}, action) => {
        switch(action.type) {
            case getType(commonActions.getUserRequest):
                return {
                    ...state,
                    loading: true
                }
            case getType(commonActions.errorGetUser):
                return {
                    ...state,
                    loading: false
                }
            case getType(commonActions.cacheUser):
                return {
                    cached: action.payload,
                    loading: false
                }
            default:
                return state
        }
    },
    businessData: (state = {loading: false}, action) => {
        switch(action.type) {
            case getType(commonActions.getPaginatedBusinessesRequest):
                return {
                    ...state,
                    loading: true
                }
            case getType(commonActions.errorGetPaginatedBusinesses):
                return {
                    ...state,
                    loading: false
                }

            case getType(commonActions.cachePaginatedBusinesses):
                return {
                    cached: action.payload,
                    loading: false
                }
            default:
                return state
        }
    },
    messages: (state = [], action) => {
        switch(action.type) {
            case getType(commonActions.consumeAllMessages):
                console.log('consume all')
                return []
            case getType(commonActions.showMessage):
                
                let newState = state.concat([action.payload])
                console.log('show message ', newState)
                return  newState
            case getType(commonActions.consumeMessage):
                if (state.length == 0) {
                    return []
                }
                // let first = state[0]
                return state.slice(1)
            default:
                return state
        }
    },
    usersData: (state = {loading: false}, action) => {
        switch(action.type) {
            case getType(commonActions.getUsersRequest):
                console.log('Reducer get user request', action.payload)
                return {
                    ...state,
                    loading: true
                }
            case getType(commonActions.cacheUsersResult):
                console.log('cache user data', action.payload)
                return {
                    cached: action.payload,
                    loading: false
                }
            case getType(commonActions.errorGetUsers):
                return {
                    ...state,
                    loading: false
                }
            default:
                return state
        }
    },
    gamesData: (state = {loading: false}, action) => {
        
        switch(action.type) {
            case getType(commonActions.getPaginatedGamesRequest):
                return {
                    ...state,
                    loading: true
                }
            case getType(commonActions.cachePaginatedGames):
                return {
                    cached: action.payload,
                    loading: false
                }
            case getType(commonActions.errorGetPaginatedGames):
                return {
                    ...state,
                    loading: false
                }
            default:
                return state
        }
    },
    spotsData: (state= {loading: false}, action) => {
        switch(action.type) {
            case getType(commonActions.getPaginatedSpotsRequest):
                console.log('getPaginatedSpotsRequest')
                return {
                    ...state,
                    loading: true
                }
            case getType(commonActions.cachePaginatedSpots):
                return {
                    cached: action.payload,
                    loading: false
                }

            case getType(commonActions.errorGetPaginatedSpots):
                return {
                    ...state,
                    loading: false
                }
            default:
                return state
        }
    }
})