let translation = {
    "location": "地点",
    "opening_hour": "开启时间",
    "phone_number": "电话号码",
    "coupon": "优惠",
    "description": "介绍",
    "download_our_app_for_more_interesting_spot": "想知更多有趣地方？下载 Scannow啦！",
    "download": "下载",
    "spot_detail": "热点",
    "remaining_xx_xx": "剩下 {{amount}}/{{max}}",
    // "valid_until": "最后兑换日期",
    "available_time": "兑换时间",
    "terms_and_condition": "条款及细则",
    "available_store": "兑换地点",
    "xx_scan_point": "{{scan_point}} 扫点",
    "prize_detail": "礼物",
    "coupon_detail": "优惠",
    "website": "Scannow 网站"
}

export default translation