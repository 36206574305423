export const SHOW_MESSAGE = 'commonfeature/SHOW_MESSAGE'
export const CONSUME_MESSAGE = 'commonfeature/CONSUME_MESSAGE'
export const CONSUME_ALL_MESSAGES = 'commonfeature/CONSUME_ALL_MESSAGES'

// normally this is only used by UserPage
export const GET_USERS_REQUEST = 'commonfeature/GET_USERS_REQUEST'

export const CACHE_USERS_RESULT = 'commonfeature/CACHE_USERS_RESULT'

export const ERROR_GET_USERS = 'commonfeature/ERROR_GET_USERS'

export const CACHE_PAGINATED_GAMES = 'commonfeature/CACHE_PAGINATED_GAMES'

export const GET_PAGINATED_GAMES_REQUEST = 'commonfeature/GET_PAGINATED_GAMES_REQUEST'

export const ERROR_GET_PAGINATED_GAMES = 'commonfeature/GET_PAGINATED_GAMES'

export const GET_PAGINATED_SPOTS_REQUEST = 'commonfeature/GET_PAGINATED_SPOTS_REQUEST'

export const ERROR_GET_PAGINATED_SPOTS = 'commonfeature/ERROR_GET_PAGINATED_SPOTS'

export const CACHED_PAGINATED_SPOTS = 'commonfeature/CACHED_PAGINATED_SPOTS'


export const GET_PAGINATED_BUSINESSES_REQUEST = 'commonfeature/GET_PAGINATED_BUSINESSES_REQUEST'
export const ERROR_GET_PAGINATED_BUSINESSES = 'commonfeature/ERROR_GET_PAGINATED_BUSINESSES'
export const CACHE_PAGINATED_BUSINESSES = 'commonfeature/CACHE_PAGINATED_BUSINESSES'

export const GET_USER_REQUEST = 'commonfeature/GET_USER_REQUEST'
export const ERROR_GET_USER = 'commonfeature/ERROR_GET_USER'
export const CACHE_USER = 'commonfeature/CACHE_USER'

export const GET_PAGINATED_USER_SHIPPING_ORDERS_REQUEST = 'commonfeature/GET_PAGINATED_USER_SHIPPING_ORDERS_REQUEST'
export const ERROR_GET_PAGINATED_USER_SHIPPING_ORDERS = 'commonfeature/ERROR_PAGINATED_GET_USER_SHIPPING_ORDERS'
export const CACHE_PAGINATED_USER_SHIPPING_ORDERS = 'commonfeature/CACHE_PAGINATED_USER_SHIPPING_ORDERS'

export const GET_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST = 'commonfeature/GET_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST'
export const ERROR_GET_PAGINATED_USER_COUPON_OWNERSHIP = 'commonfeature/ERROR_GET_PAGINATED_USER_COUPON_OWNERSHIP'
export const CACHE_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST = 'commonfeature/CACHE_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST'

export const PATCH_SHIPPING_ORDER = 'commonfeature/PATCH_SHIPPING_ORDER'

// This should be fired as new shipping order is available
export const ON_SHIPPING_ORDER_EDITED = 'commonfeature/ON_SHIPPING_ORDER_EDITED'

export const UPDATE_APP_BAR_CONFIG = 'commonfeature/UPDATE_APP_BAR_CONFIG'

export const CREATE_LOCATION = 'commonfeature/CREATE_LOCATION'

export const GET_PAGINATED_GEOGRAPHIC_LOCATION = 'commomfeature/GET_PAGINATED_GEOGRAPHIC_LOCATION'
export const CACHE_PAGINATED_GEOGRAPHIC_LOCATION = 'commonfeature/CACHE_PAGINATED_GEOGRAPHIC_LOCATION'
export const ERROR_GET_PAGINATED_GEOGRAPHIC_LOCATION = 'commonfeature/ERROR_GET_PAGINATED_GEOGRAPHIC_LOCATION'

export const CREATE_PHONE_NUMBER = 'commonfeature/CREATE_PHONE_NUMBER'

export const CREATE_GAME_BANNER = 'commonfeature/CREATE_GAME_BANNER'

export const CREATE_COUPON_BANNER = 'commonfeatuure/CREATE_COUPON_BANNER'

export const CREATE_MAJOR_BANNER = 'commonfeature/CREATE_MAJOR_BANNER'

export const CREATE_SPOT_BANNER = 'commonfeature/CREAET_SPOT_BANNER'

export const CREATE_BUSINESS = 'commonfeature/CREATE_BUSINESS'

export const CREATE_REWARD_BUNDLE = 'commonfeature/CREATE_REWARD_BUNDLE'

export const CREATE_COUPON = 'commonfeature/CREATE_COUPON'

export const CREATE_QR_CODE_ENTITY = 'commonfeature/CRAETE_QR_CODE_ENTITY'

export const GET_PAGINATED_PHONE_NUMBER = 'commomfeature/GET_PAGINATED_PHONE_NUMBER'
export const CACHE_PAGINATED_PHONE_NUMBER = 'commonfeature/CACHE_PAGINATED_PHONE_NUMBER'
export const ERROR_GET_PAGINATED_PHONE_NUMBER = 'commonfeature/ERROR_GET_PAGINATED_PHONE_NUMBER'

export const GET_PAGINATED_GAME_BANNER = 'commomfeature/GET_PAGINATED_GAME_BANNER'
export const CACHE_PAGINATED_GAME_BANNER = 'commonfeature/CACHE_PAGINATED_GAME_BANNER'
export const ERROR_GET_PAGINATED_GAME_BANNER = 'commonfeature/ERROR_GET_PAGINATED_GAME_BANNER'

export const GET_PAGINATED_COUPON_BANNER = 'commomfeature/GET_PAGINATED_COUPON_BANNER'
export const CACHE_PAGINATED_COUPON_BANNER = 'commonfeature/CACHE_PAGINATED_COUPON_BANNER'
export const ERROR_GET_PAGINATED_COUPON_BANNER = 'commonfeature/ERROR_GET_PAGINATED_COUPON_BANNER'

export const GET_PAGINATED_MAJOR_BANNER = 'commomfeature/GET_PAGINATED_MAJOR_BANNER'
export const CACHE_PAGINATED_MAJOR_BANNER = 'commonfeature/CACHE_PAGINATED_MAJOR_BANNER'
export const ERROR_GET_PAGINATED_MAJOR_BANNER = 'commonfeature/ERROR_GET_PAGINATED_MAJOR_BANNER'

export const GET_PAGINATED_SPOT_BANNER = 'commomfeature/GET_PAGINATED_SPOT_BANNER'
export const CACHE_PAGINATED_SPOT_BANNER = 'commonfeature/CACHE_PAGINATED_SPOT_BANNER'
export const ERROR_GET_PAGINATED_SPOT_BANNER = 'commonfeature/ERROR_GET_PAGINATED_SPOT_BANNER'

export const GET_PAGINATED_REWARD_BUNDLE = 'commomfeature/GET_PAGINATED_REWARD_BUNDLE'
export const CACHE_PAGINATED_REWARD_BUNDLE = 'commonfeature/CACHE_PAGINATED_REWARD_BUNDLE'
export const ERROR_GET_PAGINATED_REWARD_BUNDLE = 'commonfeature/ERROR_GET_PAGINATED_REWARD_BUNDLE'

export const GET_PAGINATED_COUPON = 'commomfeature/GET_PAGINATED_COUPON'
export const CACHE_PAGINATED_COUPON = 'commonfeature/CACHE_PAGINATED_COUPON'
export const ERROR_GET_PAGINATED_COUPON = 'commonfeature/ERROR_GET_PAGINATED_COUPON'

export const GET_PAGINATED_QR_CODE_ENTITY = 'commomfeature/GET_PAGINATED_QR_CODE_ENTITY'
export const CACHE_PAGINATED_QR_CODE_ENTITY = 'commonfeature/CACHE_PAGINATED_QR_CODE_ENTITY'
export const ERROR_GET_PAGINATED_QR_CODE_ENTITY = 'commonfeature/ERROR_GET_PAGINATED_QR_CODE_ENTITY'


// Below are page data cache!
export const CACHE_SPOT_DETAIL = 'commonfeature/CACHE_SPOT_DETAIL'
export const CACHE_COUPON_DETAIL = 'commonfeature/CACHE_COUPON_DETAIL'
export const CACHE_PRIZE_DETAIL = 'commonfeature/CACHE_PRIZE_DETAIL'
