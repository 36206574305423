import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import enTranslation from './locales/en';
import zhTranslation from './locales/zh'
import zhCnTranslation from './locales/zh_cn'
import yumLanguageDetector from './locales/yum-language-detector'
import LanguageDetector from 'i18next-browser-languagedetector';


// the translations
// (tip move them in a JSON file and import them)
export const resources = {
    en: {
      translation: enTranslation
    },
    'zh_cn': {
        translation: zhCnTranslation
    },
    zh: {
        translation: zhTranslation
    }
  };
  

const languageDetector = new LanguageDetector();
languageDetector.addDetector(yumLanguageDetector)

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .use(languageDetector)
  
  .init({
    
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: {
      default: ['en'],
      zh: ['zh', 'en'],
      'zh_hk': ['zh', 'en'],
      'zh_HK': ['zh'],
      'zh-hk': ['zh'],
      'zh-HK': ['zh'],
      'zh_cn': ['zh', 'en'],
      'zh_CN': ['zh'],
      'zh-cn': ['zh'],
      'zh-CN': ['zh']
    },
    load: 'currentOnly',
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded'
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    resources,
    detection: {
        // order and from where user language should be detected
        order: [
          // 'Yum', 
        'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      
        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
      
        // cache user language on
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
      
        // optional expire and domain for set cookie
        cookieMinutes: 10,
        cookieDomain: 'myDomain',
      
        // optional htmlTag with lang attribute, the default is:
        // htmlTag: document.documentElement,
      
        // only detect languages that are in the whitelist
        checkWhitelist: true
      }
    // backend: {
    //   loadPath: './locales/{{lng}}.json',
    // }
  });

export default i18n;