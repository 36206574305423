import {createStandardAction } from 'typesafe-actions'
import { CONSUME_MESSAGE, SHOW_MESSAGE, CONSUME_ALL_MESSAGES, GET_USERS_REQUEST, CACHE_USERS_RESULT,
    GET_PAGINATED_GAMES_REQUEST, CACHE_PAGINATED_GAMES, ERROR_GET_USERS, ERROR_GET_PAGINATED_GAMES,
 GET_PAGINATED_SPOTS_REQUEST, ERROR_GET_PAGINATED_SPOTS, CACHED_PAGINATED_SPOTS, GET_PAGINATED_BUSINESSES_REQUEST, ERROR_GET_PAGINATED_BUSINESSES, CACHE_PAGINATED_BUSINESSES, GET_USER_REQUEST, CACHE_USER, ERROR_GET_USER, 
 GET_PAGINATED_USER_SHIPPING_ORDERS_REQUEST, ERROR_GET_PAGINATED_USER_SHIPPING_ORDERS, CACHE_PAGINATED_USER_SHIPPING_ORDERS, GET_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST, CACHE_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST, ERROR_GET_PAGINATED_USER_COUPON_OWNERSHIP, PATCH_SHIPPING_ORDER, UPDATE_APP_BAR_CONFIG, ON_SHIPPING_ORDER_EDITED, CREATE_LOCATION, GET_PAGINATED_GEOGRAPHIC_LOCATION, CACHE_PAGINATED_GEOGRAPHIC_LOCATION, ERROR_GET_PAGINATED_GEOGRAPHIC_LOCATION, CREATE_PHONE_NUMBER, CREATE_GAME_BANNER, CREATE_COUPON_BANNER, CREATE_MAJOR_BANNER, CREATE_SPOT_BANNER, CREATE_BUSINESS, CREATE_REWARD_BUNDLE, CREATE_COUPON, CREATE_QR_CODE_ENTITY, GET_PAGINATED_PHONE_NUMBER, CACHE_PAGINATED_PHONE_NUMBER, ERROR_GET_PAGINATED_PHONE_NUMBER, GET_PAGINATED_GAME_BANNER, CACHE_PAGINATED_GAME_BANNER, ERROR_GET_PAGINATED_GAME_BANNER, GET_PAGINATED_COUPON_BANNER, CACHE_PAGINATED_COUPON_BANNER, ERROR_GET_PAGINATED_COUPON_BANNER, GET_PAGINATED_MAJOR_BANNER, CACHE_PAGINATED_MAJOR_BANNER, ERROR_GET_PAGINATED_MAJOR_BANNER, GET_PAGINATED_SPOT_BANNER, CACHE_PAGINATED_SPOT_BANNER, ERROR_GET_PAGINATED_SPOT_BANNER, GET_PAGINATED_REWARD_BUNDLE, CACHE_PAGINATED_REWARD_BUNDLE, ERROR_GET_PAGINATED_REWARD_BUNDLE, GET_PAGINATED_COUPON, CACHE_PAGINATED_COUPON, ERROR_GET_PAGINATED_COUPON, GET_PAGINATED_QR_CODE_ENTITY, CACHE_PAGINATED_QR_CODE_ENTITY, ERROR_GET_PAGINATED_QR_CODE_ENTITY, CACHE_SPOT_DETAIL, CACHE_COUPON_DETAIL, CACHE_PRIZE_DETAIL} from './constants'
import { Message } from './models'
import { Pagination, UserEntity, Game, Spot, Business, ShippingOrder, CouponOwnership, Translations, Location, CreateCouponParameter, PhoneNumber, GameBanner, CouponBanner, MajorBanner, SpotBanner, RewardBundle, Coupon, QRCodeEntity, Prize } from '../../api/models';
import { PaginateReq } from '../../commontypes';
import { AppBarConfig } from '../../commonui/myappbar/myappbar';


export const showMessage = createStandardAction(SHOW_MESSAGE)<
    Message>();

export const consumeMessage = createStandardAction(CONSUME_MESSAGE)()


export const consumeAllMessages = createStandardAction(CONSUME_ALL_MESSAGES)()

export const getUsersRequest = createStandardAction(GET_USERS_REQUEST)<{
    page: number;
    perPage: number;
}>();


export const cacheUsersResult = createStandardAction(CACHE_USERS_RESULT)<Pagination<UserEntity>>()

export const errorGetUsers = createStandardAction(ERROR_GET_USERS)();

export const getPaginatedGamesRequest = createStandardAction(GET_PAGINATED_GAMES_REQUEST)<{
    page: number;
    perPage: number;
}>();

export const cachePaginatedGames = createStandardAction(CACHE_PAGINATED_GAMES)<Pagination<Game>>()

export const errorGetPaginatedGames = createStandardAction(ERROR_GET_PAGINATED_GAMES)();

export const getPaginatedSpotsRequest = createStandardAction(GET_PAGINATED_SPOTS_REQUEST)<{
    page: number;
    perPage: number;
}>();

export const cachePaginatedSpots = createStandardAction(CACHED_PAGINATED_SPOTS)<Pagination<Spot>>();

export const errorGetPaginatedSpots = createStandardAction(ERROR_GET_PAGINATED_SPOTS)();

export const getPaginatedBusinessesRequest = createStandardAction(GET_PAGINATED_BUSINESSES_REQUEST)<{
    page: number;
    perPage: number;
}>();

export const errorGetPaginatedBusinesses = createStandardAction(ERROR_GET_PAGINATED_BUSINESSES)();
export const cachePaginatedBusinesses = createStandardAction(CACHE_PAGINATED_BUSINESSES)<Pagination<Business>>()

export const getUserRequest = createStandardAction(GET_USER_REQUEST)<number>();
export const errorGetUser = createStandardAction(ERROR_GET_USER)()
export const cacheUser = createStandardAction(CACHE_USER)<UserEntity>()

export const getPaginatedUserShippingOrdersRequest = createStandardAction(GET_PAGINATED_USER_SHIPPING_ORDERS_REQUEST)<{
    page: number;
    perPage: number;
    userId: number;
}>()
export const errorGetPaginatedUserShippingOrders = createStandardAction(ERROR_GET_PAGINATED_USER_SHIPPING_ORDERS)()
export const cachePaginatedUserShippingOrders = createStandardAction(CACHE_PAGINATED_USER_SHIPPING_ORDERS)<Pagination<ShippingOrder>>()

export const getPaginatedUserCouponOwnershipRequest = createStandardAction(GET_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST)<PaginateReq & { userId: number| string}>()
export const cachePaginatedUserCouponOwnership = createStandardAction(CACHE_PAGINATED_USER_COUPON_OWNERSHIP_REQUEST)<Pagination<CouponOwnership>>()

export const errorGetPaginatedUserCouponOwnership = createStandardAction(ERROR_GET_PAGINATED_USER_COUPON_OWNERSHIP)()


export const patchShippingOrder = createStandardAction(PATCH_SHIPPING_ORDER)<{
    status: number|string,
    shippingOrderId: number|string
}>()

export const onShippingOrderEdited = createStandardAction(ON_SHIPPING_ORDER_EDITED)<ShippingOrder[]>()

export const updateAppBarConfig = createStandardAction(UPDATE_APP_BAR_CONFIG)<Partial<AppBarConfig>>()

export const createLocation = createStandardAction(CREATE_LOCATION)<{
    name?: Translations,
    description?: Translations,
    latitude: string,
    longitude: string
}>()

export const getPaginatedGeographicLocationRequest = createStandardAction(GET_PAGINATED_GEOGRAPHIC_LOCATION)<PaginateReq>()
export const cachePaginatedGeographicLocation = createStandardAction(CACHE_PAGINATED_GEOGRAPHIC_LOCATION)<Pagination<Location>>()
export const errorGetPatinatedGeographicLocation = createStandardAction(ERROR_GET_PAGINATED_GEOGRAPHIC_LOCATION)()

export const createPhoneNumber = createStandardAction(CREATE_PHONE_NUMBER)<{countryCode: string, areaCode?: string, subscriberNumber: string}>()


export const createGameBanner = createStandardAction(CREATE_GAME_BANNER)<{published: boolean, gameId: number, imageId: number, url: string}>()

export const createCouponBanner = createStandardAction(CREATE_COUPON_BANNER)<{ couponId: number, imageId: number, isCurrent: boolean}>()

export const createMajorBanner = createStandardAction(CREATE_MAJOR_BANNER)<{imageId: number, url?: string, active: boolean}>()

export const createSpotBanner = createStandardAction(CREATE_SPOT_BANNER)<{spotId: number, imageId: number, isCurrent: boolean}>()

export const createBusiness = createStandardAction(CREATE_BUSINESS)<{names: Translations, descriptions: Translations, businessIconId: number}>();

export const createRewardBundle = createStandardAction(CREATE_REWARD_BUNDLE)<{
    actionUrl?: string,
    toBeCollected: boolean,
    couponIds: number[],
    creditRewardId?: number
}>();


export const createCoupon = createStandardAction(CREATE_COUPON)<CreateCouponParameter>();

export const createQRCodeEntity = createStandardAction(CREATE_QR_CODE_ENTITY)<{
    qrData? : string,
    rewardBundleId: number,
    startTime: string,
    endTime: string,
    active: boolean,
    spotId: number
}>();

export const getPaginatedPhoneNumberRequest = createStandardAction(GET_PAGINATED_PHONE_NUMBER)<PaginateReq>()
export const cachePaginatedPhoneNumber = createStandardAction(CACHE_PAGINATED_PHONE_NUMBER)<Pagination<PhoneNumber>>()
export const errorGetPatinatedPhoneNumber = createStandardAction(ERROR_GET_PAGINATED_PHONE_NUMBER)()

export const getPaginatedGameBannerRequest = createStandardAction(GET_PAGINATED_GAME_BANNER)<PaginateReq>()
export const cachePaginatedGameBanner = createStandardAction(CACHE_PAGINATED_GAME_BANNER)<Pagination<GameBanner>>()
export const errorGetPatinatedGameBanner = createStandardAction(ERROR_GET_PAGINATED_GAME_BANNER)()

export const getPaginatedCouponBannerRequest = createStandardAction(GET_PAGINATED_COUPON_BANNER)<PaginateReq>()
export const cachePaginatedCouponBanner = createStandardAction(CACHE_PAGINATED_COUPON_BANNER)<Pagination<CouponBanner>>()
export const errorGetPatinatedCouponBanner = createStandardAction(ERROR_GET_PAGINATED_COUPON_BANNER)()


export const getPaginatedMajorBannerRequest = createStandardAction(GET_PAGINATED_MAJOR_BANNER)<PaginateReq>()
export const cachePaginatedMajorBanner = createStandardAction(CACHE_PAGINATED_MAJOR_BANNER)<Pagination<MajorBanner>>()
export const errorGetPatinatedMajorBanner = createStandardAction(ERROR_GET_PAGINATED_MAJOR_BANNER)()

export const getPaginatedSpotBannerRequest = createStandardAction(GET_PAGINATED_SPOT_BANNER)<PaginateReq>()
export const cachePaginatedSpotBanner = createStandardAction(CACHE_PAGINATED_SPOT_BANNER)<Pagination<SpotBanner>>()
export const errorGetPatinatedSpotBanner = createStandardAction(ERROR_GET_PAGINATED_SPOT_BANNER)()

export const getPaginatedRewardBundleRequest = createStandardAction(GET_PAGINATED_REWARD_BUNDLE)<PaginateReq>()
export const cachePaginatedRewardBundle = createStandardAction(CACHE_PAGINATED_REWARD_BUNDLE)<Pagination<RewardBundle>>()
export const errorGetPatinatedRewardBundle = createStandardAction(ERROR_GET_PAGINATED_REWARD_BUNDLE)()

export const getPaginatedCouponRequest = createStandardAction(GET_PAGINATED_COUPON)<PaginateReq>()
export const cachePaginatedCoupon = createStandardAction(CACHE_PAGINATED_COUPON)<Pagination<Coupon>>()
export const errorGetPatinatedCoupon = createStandardAction(ERROR_GET_PAGINATED_COUPON)()

export const getPaginatedQRCodeEntityRequest = createStandardAction(GET_PAGINATED_QR_CODE_ENTITY)<PaginateReq>()
export const cachePaginatedQRCodeEntity = createStandardAction(CACHE_PAGINATED_QR_CODE_ENTITY)<Pagination<QRCodeEntity>>()
export const errorGetPatinatedQRCodeEntity = createStandardAction(ERROR_GET_PAGINATED_QR_CODE_ENTITY)()


export const cacheSpotDetail = createStandardAction(CACHE_SPOT_DETAIL)<Spot|null>();
export const cacheCouponDetail = createStandardAction(CACHE_COUPON_DETAIL)<{coupon?: Coupon, remainingAmount?: number} | null>();
export const cachePrizeDetail = createStandardAction(CACHE_PRIZE_DETAIL)<{prize?: Prize} | null>();