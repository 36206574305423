import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { resources } from '../../i18n';

const defaultTitle = "Scannow";
const defaultSep = " | ";
const defaultImage = "https://images.squarespace-cdn.com/content/5cada6275239582e438a7c84/1565145178590-4TGSDR0FI8TYW0SOF8KK/ScanNow2-02.png?content-type=image%2Fpng"
const FACEBOOK_APP_ID = '265995850683911'
const SITE_URL = process.env.SITE_URL

type OgImage = {
    url: string;
    alt?: string;
}

type OgArticle = {
    publishedTime?: string;
    modifiedTime?: string;
    expirationTime?: string;
    author?: string;
    section?: string;
    tag?: string[];
}

type Props = {
    title? : string|undefined,
    description? : string|undefined,
    // image?: string|undefined,
    image?: OgImage,
    contentType?: string|undefined,
    twitter?: string|undefined,
    noCrawl?: string|undefined,
    published?: string|undefined,
    updated?: string|undefined,
    category?: string|undefined,
    tags?: string|undefined,
    schema?: string|undefined;
}

type RealProps = RouteComponentProps & Props & WithNamespaces


const getMetaTags = ({
    title,
    description,
    image,
    contentType,
    twitter,
    noCrawl,
    published,
    updated,
    category,
    tags,
} : Props, pathname: string, t: any, i18n: any) => {
    const defaultDescription = t('website_default_descritpion') as string
    const theTitle = title ? (title + defaultSep + defaultTitle).substring(0, 60) : defaultTitle
    const theDescription = description ? description.substring(0, 60): defaultDescription
    // console.log('meta ogImage', image)
    const theImage = image ? image.url : defaultImage
    const theImageAlt = image ? image.alt : null

    console.log('title: ', title, 'theTitle', theTitle);

    const metaTags = [
        { itemprop: "name", content: theTitle },
        { itemprop: "description", content: theDescription },
        { itemprop: "image", content: theImage },
        { name: "description", content: theDescription },
        { name: "twitter:card", content: "summary_large_image" },
        // { name: "twitter:site", content: defaultTwitter },
        // { name: "twitter:title", content: theTitle },
        // { name: "twitter:description", content: theDescription },
        // { name: "twitter:creator", content: twitter || defaultTwitter },
        // { name: "twitter:image:src", content: theImage },
        { property: "og:title", content: theTitle },
        { property: "og:type", content: contentType || "website" },
        { property: "og:url", content: SITE_URL + pathname },
        { property: "og:image", content: theImage },
        { property: "og:description", content: theDescription },
        { property: "og:site_name", content: defaultTitle },
        { property: "fb:app_id", content: FACEBOOK_APP_ID },
        { property: "og.locale", content: i18n.language}
      ];

      if (theImageAlt) {
          metaTags.push({ name: "og:image:alt", content: theImageAlt});
      }
      if (noCrawl) {
        metaTags.push({ name: "robots", content: "noindex, nofollow" });
      }
  
      if (published) {
        metaTags.push({ name: "article:published_time", content: published });
      }
      if (updated) {
        metaTags.push({ name: "article:modified_time", content: updated });
      }
      if (category) {
        metaTags.push({ name: "article:section", content: category });
      }
      if (tags) {
        metaTags.push({ name: "article:tag", content: tags });
      }

      for (let key in resources) {
          metaTags.push({ name: 'og:local:alternate', content: key})
      }
      return metaTags;
}

class MetaHandler extends React.Component<RealProps> {
    render() {
        const { children, i18n, t, ...rest} = this.props
        
        return (
            <Helmet
                htmlAttributes={{
                    itemType: `http://schema.org/${rest.schema || "WebPage"}`
                }}
                title={
                    rest.title ? (rest.title + defaultSep + defaultTitle).substring(0, 60) : defaultTitle
                }
                link={[
                    {
                        rel: "canonical"
                    }
                ]}
                meta={getMetaTags(rest, this.props.location.pathname, t, i18n)}
                >
                <html lang={i18n.language}/>

            </Helmet>
        )
    }
}

export default withRouter(withNamespaces()(MetaHandler));