// tslint:disable-next-line:no-import-side-effect
import "core-js/stable";
import "regenerator-runtime/runtime";
// tslint:disable-next-line:no-import-side-effect
import 'tslib';
// tslint:disable-next-line:no-import-side-effect
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { Frontload } from 'react-frontload';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import SnackbarHandler from './commonui/snackhandler'
// import { createMuiTheme, Color } from '@material-ui/core';
import yellow from '@material-ui/core/colors/yellow';
import { BrowserRouter, Router, Route, Switch, Redirect } from "react-router-dom"
import { createHistory } from './utils';
import theme from './theme'

console.log('config env', process.env)

// const primaryColor = yellow[700];

// const theme = createMuiTheme({
//     palette: {
//       primary: {
//          main: primaryColor
//       }
//     }
//   });
  
const history = createHistory()

const Application = () => {
    React.useEffect(()=> {
        const jssStyles = document.querySelector('#jss-server-side') as Element
        if (jssStyles != null && jssStyles != undefined) {
            // const _jssStyles = jssStyles as Element
            console.log('removeChild jss-server-side')
            jssStyles!.parentNode!.removeChild(jssStyles)
        }
    })
    return (
    <Provider store={store as any}>
        <Frontload noServerRender={true}>
            {/* <Router history={history}> */}
                <BrowserRouter>
                    {/* <ThemeProvider theme={theme}> */}
                        <App/>
                    {/* </ThemeProvider> */}
                </BrowserRouter>
            {/* </Router> */}
        </Frontload>
    </Provider> 
    )
}
    


const root = document.querySelector('#root') as Element

// ReactDOM.hydrate(Application, root);
// console.log('root.hasChildNodes', root.hasChildNodes());
if (root.hasChildNodes() === true) {
    // If it's an SSR, we use hydrate to get fast page loads by just
    // attaching event listeners after the initial render
    // Loadable.preloadReady().then(() => {
    ReactDOM.hydrate(<Application/>, root);
    // });
} else {
    ReactDOM.render(<Application/>, root);
}




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
