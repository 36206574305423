// import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import HomeIcon from '@material-ui/icons/Home';
import Types from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Path } from '../../common/constants';
import { drawerActions, drawerSelectors } from '../../features/drawer';
import { withNamespaces, WithNamespaces } from 'react-i18next';

const styles = {
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  };

type Props = {
    classes: {
        list: string;
        fullList: string
    },
    opened: boolean;
}

const mapStateToProps = (state: Types.RootState) =>({
  opened: drawerSelectors.getOpened(state.drawers)
})

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) =>
  bindActionCreators(
    {
        close: drawerActions.closeDrawer,
        open: drawerActions.openDrawer
    },
    dispatch
);

type CombinedProps = WithNamespaces & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Props

type Item = {
  nameKey : string,
  icon?: React.ReactElement,
  isSeparator?: boolean,
  path: string,
  externalPath?: string
}

const itemList: Array<Item> = [
  {
    nameKey: 'website',
    icon: <HomeIcon/>,
    path: Path.home,
    externalPath: 'https://scannow.gg'
  }
]

class MyDrawer extends React.Component<CombinedProps> {

      toggle = (open: Boolean) => {
        console.log(`toggle open ${open}`)
          if (!open) {

            this.props.close()
          } else {
            this.props.open()
          }
      }
      render() {
        let { opened, t } = this.props;
          const { classes } = this.props
          const fullList = (
            <div className={classes.fullList}>
              <List>
                {
                  itemList.map(({nameKey, icon, isSeparator, path, externalPath})=> {
                    return (
                      <ListItem component='a' href={externalPath != null ? externalPath : path} button key={nameKey}>
                        {
                          icon != undefined ? <ListItemIcon>{icon}</ListItemIcon> : null
                        }
                        <ListItemText primary={t(nameKey) as string}/>
                      </ListItem>
                    )
                  }) 
                }
              </List>
            </div>
        );
            return (
                <SwipeableDrawer
                    onOpen={() => this.toggle(true)}
                    onClose={() => this.toggle(false)}
                    open={this.props.opened}
                    >
                    {fullList}
                </SwipeableDrawer>
            )


      }
  }


  export default 
  connect(
    mapStateToProps,
    mapDispatchToProps)
    (withStyles(styles)
    (withNamespaces()
      (MyDrawer))
    )