import agent from './agent';
import { AMResp } from './helpermodels';
import { Prize } from './models';

export const getPrizeById = async(prizeId: number | string, localeCode: string = 'en'): Promise<AMResp<Prize>> => {
    return agent.get(`/web/v1/prize/${prizeId}`, {
        headers: {
            'Accept-Language': `${localeCode},en;q=0.5`,
            'Override-Language': 'true'
        }
    })
}
