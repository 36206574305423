let translation = {
    "location": "Location",
    "opening_hour": "Opening Hour",
    "phone_number": "Phone Number",
    "coupon": "Coupon",
    "description": "Description",
    "download_our_app_for_more_interesting_spot": "Download Scannow App for more interesting Spot!",
    "download": "Download",
    "spot_detail": "Spot",
    "remaining_xx_xx": "Remaining {{amount}}/{{max}}",
    "valid_until": "Valid until",
    "available_time": "Available Time",
    "terms_and_condition": "Terms and Condition",
    "available_store": "Available Store",
    "xx_scan_point": "{{scan_point}} Scan Point",
    "prize_detail": "Prize",
    "coupon_detail": "Coupon",
    "website": "Scannow Website",
    "invalid_spot_id": "Invalid spot id - {{spot_id}}"
}

export default translation