import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom"
import MyAppBar from './commonui/myappbar'
import MyDrawer from './commonui/drawer'
import TestingPage from './pages/testing-page'
import HomePage from './routes/Home'

import SpotDetailPage from './pages/spotdetail'
import CouponDetail from './pages/coupondetail'
import PrizeDetailPage from './pages/prizedetail'
import { Path } from './common/constants'
import NotFoundPage from './pages/notfound'
import './i18n';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import SnackbarHandler from './commonui/snackhandler'
import yellow from '@material-ui/core/colors/yellow';
// import { createMuiTheme, Color } from '@material-ui/core';
import theme from './theme'

import WebUrlReroute from './components/weburlreroute'

// const primaryColor = yellow[700];

// const theme = createMuiTheme({
//     palette: {
//       primary: {
//          main: primaryColor
//       }
//     }
//   });

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <SnackbarHandler/>
            <Switch>
              <Route component={WebUrlReroute}/>        
            </Switch>
            <div style={{paddingTop: '100px'}}>
              <MyAppBar/>
              <MyDrawer/>
              <Switch>
                <Route path={Path.prizeDetail} component={PrizeDetailPage}/>
                <Route path={Path.spotDetail} component={SpotDetailPage}/>
                <Route path={Path.couponDetail} component={CouponDetail}/>
                <Route component={NotFoundPage}/>
              </Switch>
              {/* <TestingPage/> */}
            </div>
          </SnackbarProvider>
        </ThemeProvider>
    );
  }
}

export default App
