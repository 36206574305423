import { createMuiTheme } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";

const primaryColor = yellow[700];

export default createMuiTheme({
    palette: {
      primary: {
         main: primaryColor
      }
    }
  });
