import { AxiosError } from 'axios';
import { ErrorObj, MyResponse, Image } from '../api';
import { Message } from '../features/commonfeature/models';
import util from 'util'
import React from 'react';
import { createBrowserHistory, createMemoryHistory } from 'history';

export function isNumber(value: string | number): boolean
{
   return ((value != null) && !isNaN(Number(value.toString())));
}


export function notNull <T>(value: Array<T>|undefined| null): Array<T> {
   if (value == null) {
      return []
   }
   return value
}

export function wrapIntoArray <T>(value: T | undefined | null) {
   if (value == null || value == undefined) {
      return []
   }
   return [value]
}

export function prop<T, K extends keyof T>(obj: T, key: K) {
   return obj[key];
}


export function isAxiosError(arg: any): arg is AxiosError {
   return arg.config !== undefined
}

export function isErrorObj(arg: any): arg is ErrorObj {
   return arg.errorCode !== undefined && arg.errorMessage !== undefined
}

export function handleApiError(error: any, showMessage: (message: Message) => any) {
   let hasDoneSomething = false
   if (isAxiosError(error) && error.message !== 'Network Error') {
      console.log(error.message)
      console.log(util.inspect(error))
      let axiosError = error as AxiosError
      if (axiosError.response != null) {
         let errorObj = (axiosError.response.data as MyResponse<any>).error
         if (errorObj != null) {
            showMessage({
               message: errorObj.errorMessage
            })
            hasDoneSomething = true
         }
      }
      
   }
   
   if (!hasDoneSomething) {
      showMessage({
         message: 'Unknown Error'
      })
   }
}

/**
 * 
 * @param error 
 */
export function handleApiErrorForStream(error: any): Message {
   if (isAxiosError(error) && error.message !== 'Network Error') {
      console.log(error.message)
      console.log(util.inspect(error))
      let axiosError = error as AxiosError
      if (axiosError.response != null) {
         let errorObj = (axiosError.response.data as MyResponse<any>).error
         if (errorObj != null) {
            return {
               message: errorObj.errorMessage
            }
         }
      }
      
   }
   return {
      message: 'Unknown Error'
   }
}

export const isServer = !(
   typeof window !== 'undefined' &&
   window.document &&
   window.document.createElement
 );
 

export function createHistory(url = '/') {
   return isServer
    ? createMemoryHistory({
        initialEntries: [url]
      })
    : createBrowserHistory();
}