import agent from './agent';
import { AxiosResponse } from 'axios';
import { PhoneNumber, GameStats, MyResponse, Pagination, UserEntity, Game, Spot, Business, ShippingOrder, CouponOwnership, QRCodeUsageHistory, QRCodeEntityStats, Translations, Image, SendNotificationToUserResponse, Location, GameBanner, CouponBanner, MajorBanner, SpotBanner, RewardBundle, CreateCouponParameter, CouponForAdmin, QRCodeEntity, Coupon, RemainingAmountOfCoupon } from './models';
import { AMResp } from './helpermodels';
import { PaginateReq } from '../commontypes';

export const getCouponById = async(couponId: number | string, localeCode: string = 'en'): Promise<AMResp<Coupon>> => {
    return agent.get(`/web/v1/coupon/${couponId}`, {
        headers: {
            'Accept-Language': `${localeCode},en;q=0.5`,
            'Override-Language': 'true'
        }
    })
}

export const getRemainingAmountOfCoupon = async(couponId: number|string): Promise<AMResp<RemainingAmountOfCoupon>> => {
    return agent.get(`/web/v1/coupon/${couponId}/remaining`)
}
