import React from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import DownloadOurAppBar from '../../components/downloadourappbar';

class NotFoundPage extends React.Component<RouteComponentProps>{
    render () {
        const { history } = this.props
        return (
            <React.Fragment>
                <Container>
                    <div>
                        <div style={{textAlign: 'center'}}>
                            <h1>Page Not Found on our Server</h1>    
                        </div>
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={()=> {
                                history.push('/')
                            }}>To Scannow Website</Button>
                        </div>
                    </div>
                    
                </Container>
                <DownloadOurAppBar/>
            </React.Fragment>
        )
    }
}


export default NotFoundPage