import { Button, Container, createStyles, Grid, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

type RealProps = WithNamespaces & WithStyles<typeof styles>

type States = {

}

const styles = ({ palette, spacing }: Theme) => createStyles({
    bottomBar: {
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: 1300,
        position: 'fixed' as 'fixed',
        backgroundColor: palette.primary.main,
        paddingTop: spacing() * 2,
        paddingBottom: spacing() * 2
    } as CSSProperties
})

class DownloadOurAppBar extends React.Component<RealProps, States> {

    render() {
        const { t } = this.props;
        const { bottomBar } = this.props.classes;
        return (
            <div className={bottomBar}>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={1}>
                            <FavoriteIcon color='secondary'/>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography style={{color: 'white'}}>
                                {t('download_our_app_for_more_interesting_spot')}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button >
                                <a href="https://dynamic.scannow.gg/firebase/get-our-app" target="_blank" style={{color: 'white'}}>
                                    {t('download')}
                                </a>
                            </Button>
                        </Grid>
                    </Grid>
                    
                </Container>
            </div>
        )
    }
}

export default withStyles(styles)
(
    withNamespaces()
        (DownloadOurAppBar)
)