import React from 'react';
// import { connect } from 'react-redux'
import { bindActionCreators, Dispatch} from 'redux'
import Types from 'MyTypes'
import {commonFeatureSelectors} from '../../features/commonfeature'
import { commonFeatureActions } from '../../features/commonfeature'
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { connect } from 'react-redux';



const mapStateToProps = (state: Types.RootState) => ({
    messages: commonFeatureSelectors.getMessages(state.commonFeatures)
})

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) => 
    bindActionCreators(
        {
            consumeAllMessages: commonFeatureActions.consumeAllMessages
        },
        dispatch
    )


type RealProps = WithSnackbarProps  & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class SnackbarHandler extends React.Component<RealProps> {
    doSomething() {
        // console.log('SnackbarHandler recieve props', this.props)

        let messages  = this.props.messages
        if (messages.length > 0) {
            // let message = messages[messages.length - 1]
            messages.forEach((message)=> {
                this.props.enqueueSnackbar(
                    message.message,
                    message.options
                )
            })
            this.props.consumeAllMessages()
        }
    }


    render() {
        this.doSomething()
        // console.log('Snackbar Handler', this.props.messages)
        return null
    }
}


export default 
connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withSnackbar(SnackbarHandler)
)

