// # 0 For initial
//     # 1 for sent
//     # 2 for received
//     # 3 for require followup

//     # -1 for voided

export enum ShippingOrderState {
    initial = 0,
    sent = 1,
    received = 2,
    requireFollowup = 3,
    voided = 4
}

export const shippingOrderStateToName = {
    [ShippingOrderState.initial]: 'initial',
    [ShippingOrderState.sent]: 'sent',
    [ShippingOrderState.received]: 'received',
    [ShippingOrderState.requireFollowup]: 'Followup',
    [ShippingOrderState.voided]: 'voided'
}

export enum Path {
    notifyUser = '/notify-user',
    uploadPhoto = '/upload-photo',
    businessDetail = '/business/:businessId',
    shippingOrder = '/shipping-order',
    spotDetail = '/spot/:spotId',
    couponDetail = '/coupon/:couponId',
    prizeDetail = '/prize/:prizeId',
    gameDetail = '/game/:gameId',
    userDetail = '/user/:userId',
    business = '/business',
    spot = '/spot',
    user = '/user',
    game = '/game',
    home = '/',
    testing = '/testing',
    login = '/login',
    geographicLocation = '/geographic-location',
    phoneNumber = '/phone-number',
    gameBanner = '/game-banner',
    couponBanner = '/coupon-banner',
    majorBanner = '/major-banner',
    spotBanner = '/spot-banner',
    rewardBundle = '/reward-bundle',
    coupon = '/coupon',
    qrCodeEntity = '/qr-code-entity'
}