import agent from './agent';
import { AxiosResponse } from 'axios';
import { PhoneNumber, GameStats, MyResponse, Pagination, UserEntity, Game, Spot, Business, ShippingOrder, CouponOwnership, QRCodeUsageHistory, QRCodeEntityStats, Translations, Image, SendNotificationToUserResponse, Location, GameBanner, CouponBanner, MajorBanner, SpotBanner, RewardBundle, CreateCouponParameter, CouponForAdmin, QRCodeEntity, Coupon } from './models';
import { AMResp } from './helpermodels';
import { PaginateReq } from '../commontypes';

export const getPaginatedQRCodeEntity = async(args: PaginateReq): Promise<AMResp<Pagination<QRCodeEntity>>> => {
    return agent.get('/admin/paginated-qr-code-entity', {
        params: args
    })
}

export const getPaginatedCoupon = async(args: PaginateReq): Promise<AMResp<Pagination<Coupon>>> => {
    return agent.get('/admin/paginated-coupon', {
        params: args
    })
}

export const getPaginatedRewardBundle = async(args: PaginateReq): Promise<AMResp<Pagination<RewardBundle>>> => {
    return agent.get('/admin/paginated-reward-bundle', {
        params: args
    })
}

export const getPaginatedSpotBanner = async(args: PaginateReq): Promise<AMResp<Pagination<SpotBanner>>> => {
    return agent.get('/admin/paginated-spot-banner', {
        params: args
    })
}

export const getPaginatedMajorBanner = async(args: PaginateReq): Promise<AMResp<Pagination<MajorBanner>>> => {
    return agent.get('/admin/paginated-major-banner', {
        params: args
    })
}

export const getPaginatedCouponBanner = async(args: PaginateReq): Promise<AMResp<Pagination<CouponBanner>>> => {
    return agent.get('/admin/paginated-coupon-banner', {
        params: args
    })
}

export const getPaginatedGameBanner = async(args: PaginateReq): Promise<AMResp<Pagination<GameBanner>>> => {
    return agent.get('/admin/paginated-game-banner', {
        params: args
    })
}

export const getPaginatedPhoneNumber = async(args: PaginateReq): Promise<AMResp<Pagination<PhoneNumber>>> => {
    return agent.get('/admin/paginated-phone-number', {
        params: args
    })
}

export const createQRCodeEntity = async(args: {
    qrData? : string,
    rewardBundleId: number,
    startTime: string,
    endTime: string,
    active: boolean,
    spotId: number
}): Promise<AMResp<QRCodeEntity>> => {
    return agent.post('/admin/qr-code-entity', {
        sQRPeriods: [
            {
                startTime: args.startTime,
                endTime: args.endTime
            }
        ],
        qrData: args.qrData,
        rewardBundleId: args.rewardBundleId,
        startTime: args.startTime,
        endTime: args.endTime,
        active: args.active,
        spotId: args.spotId
    });
}

export const createCoupon = async(arg: CreateCouponParameter): Promise<AMResp<CouponForAdmin>> => {
     return agent.post('/admin/coupon', {
         coupon: arg
     })
}

export const createRewardBundle = async(creditRewardId: number|undefined, couponIds: number[], actionUrl: string|undefined, toBeCollected: boolean): Promise<AMResp<RewardBundle>> => {
    return agent.post('/admin/reward-bundle-admin-portal', {
        creditRewardId,
        couponIds,
        actionUrl,
        toBeCollected
    })
}

export const createBusiness = async(names: Translations, descriptions: Translations, businessIconId: number|undefined): Promise<AMResp<Business>> => {
    return agent.post('/admin/business', {
        names,
        descriptions,
        businessIconId
    })
}

export const createSpotBanner = async(spotId: number, imageId: number, isCurrent: boolean): Promise<AMResp<SpotBanner>> => {
    return agent.post('/admin/spot-banner', {
        spotId,
        imageId,
        isCurrent
    })
}

export const createMajorBanner = async(imageId: number, active: boolean, url?: string): Promise<AMResp<MajorBanner>> => {
    return agent.post('/admin/major-banner', {
        imageId,
        active,
        url
    })
}

export const createCouponBanner = async(couponId: number, imageId: number, isCurrent: boolean): Promise<AMResp<CouponBanner>> => {
    return agent.post('/admin/coupon-banner', {
        couponId,
        imageId,
        isCurrent
    })
}

export const createGameBanner = async(published: boolean, gameId: number, imageId: number, url: string): Promise<AMResp<GameBanner>> => {
    return agent.post('/admin/game-banner', {
        published,
        gameId,
        imageId,
        url
    })
}

export const createPhoneNumber = async(countryCode: string, areaCode: string | undefined, subscriberNumber: string): Promise<AMResp<PhoneNumber>> => {
    return agent.post('/admin/phone-number', {
        countryCode,
        areaCode,
        subscriberNumber
    })
}

export const createLocation = async(latitude: string, longitude: string, names: Translations | undefined, descriptions: Translations | undefined): Promise<AMResp<Location>> => {
    return agent.post('/admin/location', {
        latitude,
        longitude,
        names,
        descriptions
    })
}

export const sendNotificationToUsers = async(userIds: number[] |string [], titleTranslations: Translations, bodyTranslations: Translations, routeUrl: string = 'https://app.scannow.gg/home', isAll: boolean = false): Promise<AMResp<SendNotificationToUserResponse>> => {
    return agent.post('/admin/send-fcm-notification-to-users', {
        userIds,
        titleTranslations,
        bodyTranslations,
        routeUrl,
        isAll
    })
}

export const uploadImage = async(slugifiedName: string | undefined, names: Translations |undefined, objKey: string, image: File): Promise<AMResp<Image>> => {

    let form = new FormData()
    if (slugifiedName != null)
        form.set(
            'slugifiedName', slugifiedName
        )
    if (names != null) {
        form.set(
            'names', JSON.stringify(names)
        )
    }
    form.set('objKey', objKey)
    form.set('image', image)
    return agent.post('/admin/upload/image', form)
}

export const getBusinessById = async(businessId: number|string): Promise<AMResp<Business>> => {
    return agent.get(`/admin/business/${businessId}`)
}

export const patchShippingOrder = async(shippingOrderId: number|string, status: number|string): Promise<AMResp<ShippingOrder>> => {
    return agent.patch(`/admin/shipping-order/${shippingOrderId}`, {
        status
    })
}

export const getPaginatedShippingOrders = async(page: number|string, perPage: number| string, filters: String[]): Promise<AMResp<Pagination<ShippingOrder>>> => {
    return agent.post(`/admin/paginated-shipping-order`, {
        page,
        perPage,
        filters
    })
}

export const getQRCodeEntityStats = async(qrCodeEntityId: number | string) : Promise<AMResp<QRCodeEntityStats>> => {
    return agent.get(`/admin/qr-code-entity/${qrCodeEntityId}/stats`)
}

export const getGameStats = async(gameId: number|string): Promise<AMResp<GameStats>> => {
    return agent.get(`/admin/game/${gameId}/stats`)
}

export const getGameById = async(id: number|string): Promise<AMResp<Game>> => {
    return agent.get(`/admin/game/${id}`)
}

export const getPaginatedUserQRCodeUsageHistory = async(userId: number|string, page: number|string, perPage: number|string): Promise<AMResp<Pagination<QRCodeUsageHistory>>> => {
    return agent.get(`/admin/user/${userId}/paginated-qr-code-usage-history`, {
        params: {
            page,
            perPage
        }
    })
}

export const getPaginatedUserCouponOwnerships = async(userId: number|string, page: number|string, perPage: number|string): Promise<AMResp<Pagination<CouponOwnership>>> => {
    return agent.get(`/admin/user/${userId}/paginated-coupon-ownership`, {
        params: {
            page,
            perPage
        }
    })
}

export const getPaginatedUserShippingOrders = async(userId: number|string, page: number|string, perPage: number|string): Promise<AMResp<Pagination<ShippingOrder>>> => {
    return agent.get(`/admin/user/${userId}/paginated-shipping-order`, {
        params: {
            page,
            perPage
        }
    })
}

export const getUserById = async(id: number): Promise<AMResp<UserEntity>> => {
    return agent.get(`/admin/user/${id}`)
}

export const getPaginatedGeographicLocation = async(page: number, perPage: number): Promise<AMResp<Pagination<Location>>> => {
    return agent.get('/admin/paginated-geographic-location', {
        params: {
            page: page,
            perPage: perPage
        }
    }) 
}

export const getPaginatedBusiness = async(page: number, perPage: number): Promise<AMResp<Pagination<Business>>> => {
    return agent.get('/admin/paginated-business', {
        params: {
            page: page,
            perPage: perPage
        }
    }) 
}

export const getPaginatedSpots = async (page: number, perPage: number): Promise<AxiosResponse<MyResponse<Pagination<Spot>>>> => {
    return agent.get('/admin/paginated-spot', {
        params: {
            page: page,
            perPage: perPage
        }
    }) 
}

export const getPaginatedUsers = async (page: number, perPage: number, filters: string[]=[]): Promise<AxiosResponse<MyResponse<Pagination<UserEntity>>>> => {
    return agent.post('/admin/get-paginated-user', {
        page,
        perPage,
        filters
    })
} 

export const getPaginatedGames = async (page: number, perPage: number): Promise<AxiosResponse<MyResponse<Pagination<Game>>>> => {
    return agent.get('/admin/paginated-game', {
        params: {
            page,
            perPage
        }
    })
}