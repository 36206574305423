import { DetectorOptions } from 'i18next-browser-languagedetector'

/** This should be useless */
export default{
    name: 'Yum',
    lookup(options: DetectorOptions) {
        console.log('yum lookup options', options)
        return 'en'
    },
    cacheUserLanguage(lng: string, options: DetectorOptions) {
        console.log('yum cacheUserLanguage lng', lng, 'options', options)
    }
}