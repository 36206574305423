import agent from './agent';
import { AMResp } from './helpermodels';
import { Spot } from './models';

export const getSpotById = async(spotId: number | string, localeCode: string = 'en'): Promise<AMResp<Spot>> => {
    return agent.get(`/web/v1/spot/${spotId}`, {
        headers: {
            'Accept-Language': `${localeCode},en;q=0.5`,
            'Override-Language': 'true'
        }
    })
}